import React from "react"
import styled from "styled-components"
import { css } from "@emotion/core"

import { ContainerPage, WrapperPage } from "src/componentsV2/ui/layout"
import { Title, SubTitle } from "src/componentsV2/sections/Text"

import { IntegrationStyled, ListHRISIntegrationsStyled, ListSSOIntegrationsStyled } from "./style"
import oneLogin from "./images/onelogin.svg"
import okta from "./images/okta.svg"
import bamboo from "./images/bamboo.svg"
import gsuite from "./images/gsuite.svg"
import microsoft_azure from "./images/microsoft_azure.png"
import breathe_hr from "./images/breathe_hr.png"
import idaptive from "./images/idaptive.png"
import kronos from "./images/kronos.png"
import active_directory from "./images/active_directory.png"
import paylocity from "./images/paylocity.png"
import people_hr from "./images/people_hr.png"
import ultipro from "./images/ultipro.png"
import integrationsDesktopJPG from './images/integration_bg.jpg'
import integrationsDesktopWEBP from './images/integration_bg.webp'
import integrationsDesktop1024JPG from './images/integration_bg_1024x611.jpg'
import integrationsDesktop1024WEBP from './images/integration_bg_1024x611.webp'
import integrationsMobileJPG from './images/integration_img_mobile.jpg'
import integrationsMobileWEBP from './images/integration_img_mobile.webp'
import integrationsMobile425JPG from './images/integration_img_mobile_425x469.jpg'
import integrationsMobile425WEBP from './images/integration_img_mobile_425x469.webp'

interface DataProps {
  image: any
  url?: string
  alt: string
  title: string
}

const listSSO: Array<DataProps> = [
  { image: gsuite, url: "https://workspace.google.com/", alt: "google_workspace", title: "Plug and Play Google Workspace Integration for SSO" },
  { image: active_directory, alt: "active_directory", title: "Plug and Play Microsoft Active Directory Integration for SSO" },
  { image: oneLogin, url: "https://www.onelogin.com/", alt: "oneLogin", title: "Plug and Play OneLogin Integration for SSO" },
  { image: okta, url: "https://www.okta.com/", alt: "okta", title: "Plug and Play Okta Integration for SSO" },
  { image: microsoft_azure, url: "https://azure.microsoft.com/", alt: "microsoft_azure", title: "Plug and Play Microsoft Azure Integration for SSO" },
  { image: idaptive, url: "https://www.cyberark.com/", alt: "idaptive", title: "Plug and Play Idaptive Integration for SSO" },
  { image: bamboo, url: "https://www.bamboohr.com/", alt: "bambooHr", title: "BambooHR Integration for HRIS" },
  { image: breathe_hr, url: "https://www.breathehr.com/", alt: "breathe_hr", title: "Breathe HR Integration for HRIS" },
  { image: paylocity, url: "https://www.paylocity.com/", alt: "paylocity", title: "Paylocity Integration for HRIS" },
  { image: kronos, url: "https://www.ukg.com/solutions/ukg-ready-suite", alt: "ukg_ready", title: "UKG Ready Integration for HRIS" },
  { image: ultipro, url: "https://www.ukg.com/solutions/ukg-pro-suite", alt: "ukg_pro", title: "UKG Pro Integration for HRIS" },
  { image: people_hr, url: "https://www.peoplehr.com/", alt: "people_hr", title: "People HR Integration for HRIS" },
]

const ListSSO = (props: DataProps) => {
  const { image, alt, url, title } = props
  return (
    <div className={`item ${url ? 'cursor-pointer' : ''}`} title={title}>
      <a target="_blank" href={url}>
        <img src={image} alt={alt} />
      </a>
    </div>
  )
}

const IntegrationContainer = styled(ContainerPage)`
  padding-bottom: 0px;
  @media (min-width: 768px) {
    padding-bottom: 0px;
    .title {
      text-align: center;
    }
  }
`

const index = () => {
  return (
    <IntegrationContainer id="integration" isBackgroundWhite isWidthFull data-loadable-component="sso-hris-integration" >
      <WrapperPage isWidthFull>
        <IntegrationStyled>
          <div className="flex flex-col justify-around relative md:flex-row mb-20 md:mb-37.5 md:px-30">
            <div className="max-w-132 mx-auto md:mx-0 mb-11.5 md:mb-0">
              <Title className="mb-5 title">
                <div className="md:text-left md:pr-8">
              AirMason has plug and play integrations with each of the following platforms
                </div>
              </Title>
              <SubTitle className="sub-title">
                <div className="md:text-left md:pr-24">
              SSO integrations allow your team members to sign into their handbooks with 
              a single login across your organization.
                </div>
              </SubTitle>
            </div>

            <ListSSOIntegrationsStyled>
            <div className="flex items-center justify-around md:justify-between w-full flex-row">
                {listSSO.slice(0, 2).map((item, index) => (
                  <ListSSO image={item.image} url={item.url} alt={item.alt} key={index} title={item.title} />
                ))}
              </div>
              <div className="flex items-center justify-around w-full flex-row">
                {listSSO.slice(2, 4).map((item, index) => (
                  <ListSSO image={item.image} url={item.url} alt={item.alt} key={index} title={item.title} />
                ))}
              </div>
              <div className="flex items-center justify-around md:justify-between w-full flex-row">
                {listSSO.slice(4, 6).map((item, index) => (
                  <ListSSO image={item.image} url={item.url} alt={item.alt} key={index} title={item.title} />
                ))}
              </div>
            </ListSSOIntegrationsStyled>
          </div>
          <div className="flex flex-col relative md:flex-col">
            <div className="max-w-132 mx-auto mb-11.5 md:mb-0">
              <Title className="mb-5 title text-center">
                Our HRIS integrations
              </Title>
              <SubTitle className="sub-title px-4">
                Automatically keep employee records updated so new employees
                automatically get access to the appropriate handbook and policies
                based on their team and location.
              </SubTitle>
            </div>

            <ListHRISIntegrationsStyled>
              <div className="flex items-center justify-around w-full flex-col md:flex-row">
                {listSSO.slice(6, 9).map((item, index) => (
                  <ListSSO image={item.image} url={item.url} alt={item.alt} key={index} title={item.title} />
                ))}
              </div>
              <div className="flex items-center justify-around w-full flex-col md:flex-row">
                {listSSO.slice(9, 12).map((item, index) => (
                  <ListSSO image={item.image} url={item.url} alt={item.alt} key={index} title={item.title} />
                ))}
              </div>
            </ListHRISIntegrationsStyled>
          </div>
          <picture>
            <source srcSet={integrationsDesktopWEBP} type="image/webp" media="(min-width: 1025px)" />
            <source srcSet={integrationsDesktopJPG} type="image/jpg" media="(min-width: 1025px)" />
            <source srcSet={integrationsDesktop1024WEBP} type="image/webp" media="(min-width: 769px)" />
            <source srcSet={integrationsDesktop1024JPG} type="image/jpg" media="(min-width: 769px)" />
            <source srcSet={integrationsMobileWEBP} type="image/webp" media="(min-width: 426px)" />
            <source srcSet={integrationsMobileJPG} type="image/jpg" media="(min-width: 426px)" />
            <source srcSet={integrationsMobile425WEBP} type="image/webp" />
            <source srcSet={integrationsMobile425JPG} type="image/jpg" />
            <img src={integrationsDesktopJPG}
              width="100%"
              height="90vh"
              alt='integration'
              css={css`
                margin-top: -30px;
                margin-bottom: -20px;
                width: 100%;
                height: 90vw;
                text-align: center;

                @media (min-width: 414px) {
                  margin-top: -70px;
                }

                @media (min-width: 540px) {
                  margin-top: -100px;
                }

                @media (min-width: 768px) {
                  display: none;
                }

                @media (min-width: 768px) {
                  margin-top: -185px;
                  height: 62vw;
                  display: block;
                }

                @media (min-width: 1024px) {
                  margin-top: -250px;
                }

                @media (min-width: 1280px) {
                  margin-top: -290px;
                }

                @media (min-width: 1500px) {
                  margin-top: -360px;
                }

                @media (min-width: 1680px) {
                  margin-top: -380px;
                }
            `} />
          </picture>
        </IntegrationStyled>
      </WrapperPage>
    </IntegrationContainer>
  )
}

export default index
